/**
 * 手动引入组件注册
 * 看了一些教程，可以使用插件进行自动引入，代码确实精简了很多，但是自动引入在实现上不仅仅是针对简单的组件，所以在性能上不知道影响多少，后续可以做对比。
 */
import {
  Alert,
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Result,
  Select,
  Space,
  Spin,
  Table as AntdTable,
  Tabs,
  Tooltip,
  Image
} from 'ant-design-vue';

import type { App } from 'vue';

import Icon from '@/components/Icon/index.vue';


export function setupComponents(app: App<Element>) {
  app.component('Icon', Icon);

  app
    .use(Alert)
    .use(Avatar)
    .use(Breadcrumb)
    .use(Button)
    .use(Card)
    .use(Col)
    .use(DatePicker)
    .use(Divider)
    .use(Dropdown)
    .use(Form)
    .use(Input)
    .use(Layout)
    .use(Menu)
    .use(Modal)
    .use(Popconfirm)
    .use(Row)
    .use(Result)
    .use(Select)
    .use(Space)
    .use(Spin)
    .use(AntdTable)
    .use(Tabs)
    .use(Tooltip)
    .use(Image)
    ;
}
