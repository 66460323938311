<template>
  <!-- /** Icon封装，使用的是阿里的图标库 */ -->
  <icon-font :style="iconStyle" :class="iconClass" :type="iconType" />
</template>
<script lang="ts">
  import { defineComponent, computed } from 'vue'; 
  import { createFromIconfontCN } from '@ant-design/icons-vue';

  /**
   * @name: 
   * @description: 阿里图标库，请注意scriptUrl的来源（目前是我<周跃>的个人账号）
   */  
  const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_3802104_ftf0ijd4naq.js',
  });

  export default defineComponent({
    components: {
      IconFont,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      className: {
        type: String,
        default: '',
      },
      size: {
        type: String,
        default: '18px',
      },
    },
    setup(props) {
      return {
        iconType: computed(() => `icon-sxy-self-service_${props.type}`),
        iconClass: computed(() => (props.className ? `my-icon ${props.className}` : 'my-icon')),
        iconStyle: computed(() => {
          const style = {};
          // @ts-ignore
          if (props.size) style['font-size'] = props.size;
          return style;
        }),
      };
    },
  });
</script>
<style scoped>
  .my-icon {
    font-size: 18px;
    /* vertical-align: middle; */
    fill: currentColor;
  }
</style>
