/**
 * @name permission
 * @description 全局路由过滤、权限过滤
 */

import { router } from '.';
import { getToken } from '../utils/storage';
import { usePermissioStoreWithOut } from '@/store/modules/permission';

const permissioStore = usePermissioStoreWithOut();
const whiteList = ['/login'];

/**
 * @name: 
 * @description: 路由思想完全参考了hades（甚至考虑到了权限），其实没必要，因为只是一个单页面，甚至都不需要复杂的layout。
 */
router.beforeEach(async (to: any, _, next) => {
  const hasToken = getToken();
  console.log("==========================", to.path);
  if (hasToken) {
    // 已登录
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      //是否获取过用户信息
      const isGetUserInfo = permissioStore.getIsGetUserInfo;
      if (isGetUserInfo) {
        next();
      } else {
        // 没有获取，请求数据
        await permissioStore.fetchAuths();
        // 过滤权限路由
        const routes = await permissioStore.buildRoutesAction();
        routes.forEach((route) => {
          router.addRoute(route);
        });

        next({ ...to, replace: true });
      }
    }
  } else {
    // 未登录
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
    }
  }
});
