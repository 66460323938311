import BasicLayout from '@/layouts/BasicLayout/index.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';
import type { RouteRecordRaw } from 'vue-router';

export const accessRoutes: RouteRecordRaw[] = [
  {
    path: '/app',
    name: 'app',
    component: BasicLayout,
    redirect: '/app/home',
    meta: { title: '随薪意' },
    children: [
      {
        path: '/app/home',
        component: () => import('@/views/home/index.vue'),
        name: 'home',
        meta: {
          title: '资料上传',
          icon: 'materials',
          auth: ['home'],
        },
      },
      {
        path: '/sys/account',
        name: 'account',
        component: () => import('@/views/account/index.vue'),
      },
    ],
  },
];

const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    name: 'login',
    meta: { title: '登录' },
  },
  {
    path: '/',
    name: 'Root',
    redirect: '/app',
    meta: {
      title: 'Root',
    },
  },
];

export const publicRoutes = [
  {
    path: '/redirect',
    component: BlankLayout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
  },
];

export default constantRoutes;
