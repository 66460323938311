import { createApp } from "vue";
import "./style.css";
import '@/styles/index.less';
import 'ant-design-vue/dist/antd.css';
import App from "./App.vue";
import { router } from '@/router';
import { store } from '@/store';
import './router/permission';
import { setupComponents } from './plugin';


const app = createApp(App);

app.use(store);

app.use(router);

setupComponents(app);

app.mount('#app');

