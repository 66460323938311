import { defineStore } from 'pinia';
import { store } from '@/store';
import { ResUserInfo, LoginRes } from '@/api/user/model';
import { getToken, setToken, removeToken, setUser, getApplyId, setApplyId } from '@/utils/storage';
// import { router } from '@/router';

interface UserState {
  token: string;
  sxyStatus:  number;
  applyId: string;
  auths: string[];
}

export const useUserStore = defineStore({
  id: 'app-user',
  state: (): UserState => ({
    token: '',
    sxyStatus: 0,
    applyId: '',
    auths: [],
  }),
  getters: {
    getToken(): string {
      return this.token || getToken();
    },
    getSxyStatus(): number {
      return this.sxyStatus || 0;
    },
    getApplyId(): string {
      return this.applyId || getApplyId();
    }
  },
  actions: {
    setToken(info: string) {
      this.token = info ?? '';
      setToken(info);
    },
    setAuth(auths: string[]) {
      this.auths = auths;
    },
    resetState() {
      this.token = '';
      this.auths = [];
    },
    /**
     * @description: login
     */
    login(params: LoginRes) {
      let result = false;
      if (!!params === true && params.jwt) {
        this.setToken(params.jwt);
        setUser(params.bizData?.userInfo);
        const _applyId = (params.bizData?.applyInfo as any)?.id || '';
        this.applyId = _applyId;
        setApplyId(_applyId)
        result = true;
      }
      return result;
    },

    /**
     * @description: logout
     */
    async logout() {
      
      this.resetState();
      removeToken();
      // router.replace('/login');
      //
      /**
       * 路由表重置
       * 直接reload就行，因为reload会触发token缺失带来的跳转登录机制。
       * 这儿的做法是有局限性的，但是reload和router.replace不能并存，并存相当于两次刷新，后续再说。
       */
      location.reload();
    },
  },
});

// Need to be used outside the setup
export function useUserStoreWithOut() {
  return useUserStore(store);
}
