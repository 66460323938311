<template>
  <div class="sys-setting">
    <a-dropdown placement="bottom">
      <template #overlay>
        <a-menu :selectedKeys="selectedKeys" class="menu-box">
          <a-menu-item v-for="item in navs" :key="item.path" @click="handleOper(item?.path)">
            <template #icon>
              <Icon size="20px" :type="item.icon" />
            </template>
            <span>{{ item.name }}</span>
          </a-menu-item>
        </a-menu>
      </template>
      <a-space class="wrap" align="baseline" direction="horizontal">
        <Icon type="setting" />
        <span class="setting">设置</span>
        <Icon type="arrow" />
      </a-space>
    </a-dropdown>
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router';
  import { ref, watchEffect, unref } from 'vue';
  import { useUserStore } from '@/store/user';
  import { navs as settingNavs } from './constant';
  import { usePermissioStore } from '@/store/modules/permission';

  const store = useUserStore();
  const permissioStore = usePermissioStore();
  const router = useRouter();

  const navs = ref(settingNavs);
  const selectedKeys = ref<string[]>([]);

  watchEffect(() => {
    const modules = permissioStore.getModules;
    if (modules.length) {
      navs.value = unref(navs).filter((n) => (n.auth ? modules.includes(n.auth) : true));
    }
  });

  watchEffect(() => {
    if (router.currentRoute) {
      const matched = router.currentRoute.value.matched.concat();
      selectedKeys.value = matched.filter((r) => r.name !== 'index').map((r) => r.path);
    }
  });

  const handleOper = (path?: string) => {
    if (path) return router.push(path);
    store.logout();
  };
</script>

<style lang="less" scoped>
  .sys-setting {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-right: 16px;
    .wrap {
      height: 55px;

      .setting {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 8px 0 4px;
      }
    }
    .my-icon {
      font-size: 18px !important;
    }
  }
  .menu-box :deep(.ant-dropdown-menu-item) {
    width: 142px;
    height: 42px;
    line-height: 42px;
    padding: 0 16px;
  }
  .menu-box :deep(.ant-dropdown-menu-item-selected) {
    background: #eaeffe;
    color: #3860f4;
  }
</style>
