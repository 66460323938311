/**
 * @name:
 * @description: 路由处理工具
 */

import { RouteRecordRaw } from 'vue-router';
import intersection from 'lodash-es/intersection';


// 不需要权限过滤的 白名单

type IAuth = { auth?: string[] };

export const filterAsyncRoutes = (routes: RouteRecordRaw[], modules: string[]): RouteRecordRaw[] => {
  const res: RouteRecordRaw[] = [];
  routes.forEach((route) => {
    const { auth } = (route.meta as IAuth) || {};
    if (!auth) {
      if (route.children) {
        route.children = filterAsyncRoutes(route.children, modules);
      }
      res.push(route);
    } else {
      if (intersection(modules, auth).length > 0) {
        if (route.children) {
          route.children = filterAsyncRoutes(route.children, modules);
        }
        res.push(route);
      }
    }
  });
  return res;
};
