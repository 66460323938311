/**
 * 右上角配置
 */
export const navs = [
  {
    icon: 'personal',
    name: '账号中心',
    path: '/sys/account',
    auth: '',
  },
  {
    icon: 'logout',
    name: '退出登录',
    auth: '',
  },
];
