import Cookies from 'js-cookie';
const TokenKey = 'x-auth-token';
const UserKey = 'x-auth-user';
const ApplyInfoKey = 'x-apply-key';

export function getToken() {
  return Cookies.get(TokenKey) || '';
}

export function setToken(token: string) {
  Cookies.set(TokenKey, token, { expires: 1 });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function setUser(user?: object) {
  return localStorage.setItem(UserKey, JSON.stringify(user));
}

export function getUser() {
  const userStr = localStorage.getItem(UserKey) as string;
  const userObj = JSON.parse(userStr);
  if (typeof userObj !== 'object') {
    return undefined;
  }
  return userObj;
}

export function getApplyId() {
  return localStorage.getItem(ApplyInfoKey) || '';
}

export function setApplyId(applyId: string) {
  localStorage.setItem(ApplyInfoKey, applyId);
}
